<template>
	<div>
		<div style="display: flex;">
			<div style="margin: 22px 0 0 10px;">
				<el-form :inline="true">
					<el-form-item label="年份">
						<el-date-picker v-model="value3" type="year" placeholder="选择年" format="yyyy">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="月份">
						<el-date-picker v-model="value2" type="month" placeholder="选择月" format="MM">
						</el-date-picker>
					</el-form-item>
				</el-form>
			</div>
			<div slot="action" style="margin: auto 0;">
				<el-button size="small" type="primary"
					style="margin: 0 0 0 10px;background-color: rgba(40, 168, 207, 1);"
					@click="searchTable">搜索</el-button>
				<el-button size="small" @click="resetTable">重置</el-button>
			</div>
		</div>
		<div style="margin: 0 10px;">
			<el-table :data="tableData" style="width: 100%" border>
				<el-table-column label="本月收入" align="center" prop="addresser">
					<template slot-scope="scope">{{scope.row.sendUsername}}</template>
				</el-table-column>
				<el-table-column label="分成比例" align="center" prop="title">
				</el-table-column>
				<el-table-column label="月分成额" align="center" prop="time">
				</el-table-column>
				<el-table-column label="结算时间" align="center" prop="time">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				value2: '',
				value3: '',
				getKey: ''
			}
		},
		methods: {
			handleSelectionChange() {

			},
			openDetails() {

			},
			searchTable() {

			},
			resetTable() {

			}
		}
	}
</script>

<style>
</style>